<template>
  <div id="bank-account">
    <CardHeader ref="header" :node="headerTitle" />
    <div id="content-scroll" class="content-scroll">
      <div class="bank-account text-left f-w3">
        <p class="bank-account__title">口座情報</p>
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(submitForm)">
            <div class="bank-account__content position-relative">
              <div
                class="option-type-account position-absolute"
                v-if="isShowOption"
                ref="option-type-account"
              >
                <div v-for="(option, index) in options" :key="index">
                  <div
                    class="d-flex justify-content-between align-items-center"
                    @click="chooseOption(index, option.text, option.value)"
                  >
                    <p>{{ option.text }}</p>
                    <b-icon
                      icon="check2"
                      v-if="index === indexOption"
                      class="icon-check"
                    />
                  </div>
                  <hr v-if="index < options.length - 1" />
                </div>
              </div>
              <ValidationProvider
                name="銀行"
                rules="required|min:4|max:255"
                v-slot="{ errors }"
              >
                <div class="row">
                  <p class="f-w6 col-4 mb-0">銀行</p>
                  <input
                    type="text"
                    class="text-right col-8 f-w3"
                    placeholder="銀行を入力してください。"
                    v-model="registerBank.bank_name"
                    v-on:blur="handleBlur()"
                    @keyup="changeBankName"
                  />
                  <span class="error f-w3">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
              <hr />
              <ValidationProvider
                name="口座種別"
                rules="required"
                v-slot="{ errors }"
              >
                <input v-model="text" style="display: none" />
                <div class="row" @click="showOptions()">
                  <p class="f-w6 mb-0 col-4">口座種別</p>
                  <div class="col-8 text-right f-w3">
                    <span class="bank-account__content__text">{{ text }}</span>
                    <b-icon
                      :icon="isShowOption ? 'chevron-down' : 'chevron-right'"
                      class="bank-account__content__icon"
                    />
                  </div>
                  <span class="error f-w3">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
              <hr />
              <ValidationProvider
                name="支店コード"
                rules="required|min:2|max:255"
                v-slot="{ errors }"
              >
                <div class="row">
                  <p class="f-w6 col-4 mb-0">支店コード</p>
                  <input
                    type="text"
                    class="text-right col-8 f-w3"
                    placeholder="支店コードを入力してください。"
                    v-model="registerBank.branch_code"
                    v-on:blur="handleBlur()"
                    @keyup="changeBranchName"
                  />
                  <span class="error f-w3">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
              <hr />
              <ValidationProvider
                name="口座番号"
                rules="required|regex:\d{7}$|digits:7"
                v-slot="{ errors }"
              >
                <div class="row">
                  <p class="f-w6 col-4 mb-0">口座番号</p>
                  <input
                    type="text"
                    class="text-right col-8 f-w3"
                    placeholder="口座番号を入力してください。"
                    v-model="registerBank.account_number"
                    v-on:blur="handleBlur()"
                    @keyup="changeAccountNumber"
                  />
                  <span class="error f-w3">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </div>
            <p class="bank-account__note">
              ※口座番号が7桁未満の場合は先頭に0をつけてください
            </p>
            <ValidationProvider
              name="口座名義"
              rules="required|min:2|max:255"
              v-slot="{ errors }"
            >
              <div v-if="windowWidth <= 320" class="row nickname">
                <div class="nickname__text f-w6 col-5 mb-0">
                  <p>口座名義</p>
                  <p style="margin-left: -10px">（セイメイ)</p>
                </div>
                <input
                  type="text"
                  class="nickname__placeholder text-right col-7"
                  placeholder="口座名義を入力してください。"
                  v-model="registerBank.account_name"
                  v-on:blur="handleBlur()"
                  @keyup="changeAccountName"
                />
                <span class="error f-w3">{{ errors[0] }}</span>
              </div>
              <div v-else class="row nickname">
                <p class="nickname__text f-w6 col-5 mb-0">
                  口座名義（セイメイ）
                </p>
                <input
                  type="text"
                  class="nickname__placeholder text-right col-7"
                  placeholder="口座名義を入力してください。"
                  v-model="registerBank.account_name"
                  v-on:blur="handleBlur()"
                  @keyup="changeAccountName"
                />
                <span class="error f-w3">{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
            <p class="bank-account__p bank-account__p--top">
              ※振込先が間違っている場合、再度振込手数料が発生します。
            </p>
            <p class="bank-account__p bank-account__p--center">
              ※登録された氏名と売上金（もしくはメルベイ残高）の振込口座名義が一致しない場合、振込申請をすることはできません。
            </p>
            <p class="bank-account__p bank-account__p--bottom">
              ※振込先口座は、ご本人名義の口座のみご利用いただけます。口座名義が旧姓の場合は、名義変更のうえ、改めてお手続きをお願いします。
            </p>
            <div class="bank-account__button">
              <button>次へ</button>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
    <CardFooter ref="footer" />
  </div>
</template>

<script>
import CardHeader from "@/components/CardHeader.vue";
import CardFooter from "@/components/CardFooter.vue";

export default {
  components: {
    CardHeader,
    CardFooter
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      indexOption: null,
      isShowOption: false,
      text: null,
      headerTitle: {
        text: "銀行口座登録",
        isShowTitle: true,
        isShowLogo: false
      },
      registerBank: {
        bank_name: "",
        branch_code: "",
        account_type: "",
        account_number: "",
        account_name: ""
      },
      options: [
        {
          text: "普通預金",
          value: "1"
        },
        {
          text: "当座預金",
          value: "2"
        },
        {
          text: "貯蓄預金",
          value: "3"
        }
      ]
    };
  },
  methods: {
    handleBlur() {
      this.registerBank.bank_name = this.registerBank.bank_name.trim();
      this.registerBank.branch_code = this.registerBank.branch_code.trim();
      this.registerBank.account_number = this.registerBank.account_number.trim();
      this.registerBank.account_name = this.registerBank.account_name.trim();
    },
    changeBankName(e) {
      this.registerBank.bank_name = e.target.value;
    },
    changeAccountNumber(e) {
      this.registerBank.account_number = e.target.value;
    },
    changeBranchName(e) {
      this.registerBank.branch_code = e.target.value;
    },
    changeAccountName(e) {
      this.registerBank.account_name = e.target.value;
    },
    showOptions() {
      this.isShowOption = !this.isShowOption;
    },
    chooseOption(index, text, value) {
      this.indexOption = index;
      this.registerBank.account_type = value;
      this.$refs["option-type-account"].classList.add(
        "option-type-account--transition"
      );
      setTimeout(() => ((this.isShowOption = false), (this.text = text)), 400);
    },
    async submitForm() {
      this.$root.$refs.loading.start();
      await this.$store
        .dispatch("register/updateBankAccount", this.registerBank)
        .then(() => {
          this.$toast("登録成功", "通知", "success");
          this.$router.go(-1);
          this.$root.$refs.loading.finish();
        })
        .catch(() => {
          this.$root.$refs.loading.finish();
          this.$toast("登録に失敗しました。", "通知", "danger");
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_fontFamily.scss";
@import "~@/assets/scss/cast/bankAccount.scss";

.content-scroll {
  // overflow-y: scroll;
  padding-top: 40px;
  overflow-x: hidden;
  // height: calc(100vh - 108px);
}

@media screen and (min-width: 1200px) {
  // .content-scroll {
  //   // height: calc(100vh - 152px);
  // }
}
</style>
